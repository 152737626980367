import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./ListSkill.css";

function ListSkill() {
  return (
    <div className="listskill">
      <h2>Skills</h2>
        <ul className="listskill-container">
         <i class="fas fa-laptop-code"><p>Front-end Web Development</p></i>
          <li><span>Responsive Web Design</span></li>
          <li><span>HTML5</span></li>
          <li><span>CSS</span></li>
          <li><span>Bootstrap</span></li>
          <li><span>jQuery</span></li>
          <li><span>Javascript</span></li>
          <li><span>React</span></li>
        </ul>
        
        <ul className="listskill-container">
        <i class="fas fa-pencil-ruler"><p>Mechanical Engineering</p></i>
          <li><span>Structural Analysis</span></li>
          <li><span>Drafting</span></li>
          <li><span>Computer-aided Design: AutoCAD</span></li>
          <li><span>Metalworking</span></li>
          <li><span>Computer Numerical Control Machinery</span></li>
          <li><span>Thermodynamics and thermo-science</span></li>
          <li><br></br> </li>
        </ul>
                
        <ul className="listskill-container">
        <i class="fas fa-tty"><p>Information Technology</p></i>
          <li><span>Technical Support</span></li>
          <li><span>Computer Networking</span></li>
          <li><span>Linux System Administration</span></li>
          <li><span>macOS/OS-X System Administration</span></li>
          <li><span>Incident Management (ITSM)</span></li>
          <li><span>Telecommunications</span></li>
          <li><br></br></li>
        </ul>
      </div> 
      );
}

export default ListSkill;
