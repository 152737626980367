import React from 'react'; 
import '../../App.css'
import MyProfile from '../MyProfile';
import Cards from '../Cards';
import ListSkill from '../ListSkill';



function Home ()    {
    return(
        <>
            <MyProfile />
            <ListSkill />
            <Cards />
                        </>
    );

}

export default Home;