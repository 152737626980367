import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./MyProfile.css";

function MyProfile() {
  return (
    <div className="profile-parent">
      <div className="profile-child">

        <img className="avatar" src="/images/ProfilePicture.jpg" alt="image" />
        <p>Hi, </p>
        <br></br>
        <p>My name is Danny, I work as an Engineer for Technical Services at Vodafone.
          I'm interested in (learning more about) Information Communications Technology and Information Security.</p>
          <br></br>
          <p>Some of my favourite pastime activities are mountainbiking, front-end web development, fitness, playing guitar and writing music.</p>
        </div>
    </div>
  );
}

export default MyProfile;
