import React from "react";
import Navbar from "./components/Navbar";
import "./App.css";
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Skills from './components/pages/Skills';
import Accomplishments from './components/pages/Accomplishments';
import Contact from './components/pages/Contact';


function App() {
  return (
    <>
    <Router>
        <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/skills' component={Skills} />
        <Route path='/accomplishments' component={Accomplishments} />
        <Route path='/contact' component={Contact}  />
      </Switch>
    </Router>
    </>
  );
}

export default App;
