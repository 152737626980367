import React from 'react'; 
import '../../App.css'
import Cards from '../Cards';
import ListSkill from '../ListSkill';

export default function Skills() {
  return <>
    <ListSkill />
    <Cards />
    </>;
}