import React from 'react'
import CardItem from './CardItem'
import './Cards.css';

function Cards() {
    return (
        <div className='cards'>
            <h2>Accomplishments</h2>
            <div className="cards__wrapper">
                <ul className="cards__items">
                    <CardItem
                    src="images\Coursera_Google_Networking_Bits.jpg"
                    text="Computer Networking"
                    label="IT"
                    path="/accomplishments" />
                    <CardItem
                    src="images\Coursera_Google_IT_Support_Fundamentals.jpg"
                    text="IT Support Fundamentals"
                    label="IT"
                    path="/accomplishments" />
                    <CardItem
                    src="images\Pentester_Lab_Unix_Badge.jpg"
                    text="Linux Privilige Escalation"
                    label="InfoSec"
                    path="/accomplishments" />
                    <CardItem
                    src="images\FreeCodeCamp_Responsive_Web_Design.jpg"
                    text="Responsive Web Design"
                    label="Web Design"
                    path="/accomplishments" />
                </ul>
                <ul className="cards__items">
                    <CardItem
                    src="images\Cybrary_Networking_Fundamentals_For_Security_Practitioners.jpg"
                    text="Networking Fundamentals"
                    label="InfoSec"
                    path="/accomplishments" />
                    <CardItem
                    src="images\Cybrary_ITIL_Foundations.jpg"
                    text="ITIL Fundamentals"
                    label="IT"
                    path="/accomplishments" />
                    <CardItem
                    src="images\Cybrary_Cyber_Threat_Intelligence.jpg"
                    text="Cyber Threat Intelligence"
                    label="InfoSec"
                    path="/accomplishments" />
                    <CardItem
                    src="images\Cybrary_Nessus_Fundamentals.jpg"
                    text="Nessus Fundamentals"
                    label="InfoSec"
                    path="/accomplishments" />
                </ul>
                <ul className="cards__items">
                    <CardItem
                    src="images\Cybrary_TCP_IP.jpg"
                    text="TCP/IP"
                    label="IT"
                    path="/accomplishments" />
                    <CardItem
                    src="images\Cybrary_EU_Social_Engineering.jpg"
                    text="Social Engineering"
                    label="InfoSec"
                    path="/accomplishments" />
                    <CardItem
                    src="images\Cybrary_CompTIA_Linux.jpg"
                    text="Linux System Administration"
                    label="IT"
                    path="/accomplishments" />
                    <CardItem
                    src="images\Cybrary_HIPAA.jpg"
                    text="HIPAA"
                    label="Privacy"
                    path="/accomplishments" />
                </ul>
                <ul className="cards__items">
                <CardItem
                    src="images\Cybrary_Network_Security.jpg"
                    text="Network Security"
                    label="InfoSec"
                    path="/accomplishments" />
                    <CardItem
                    src="images\Cybrary_Security_Awareness.jpg"
                    text="Security Awareness"
                    label="InfoSec"
                    path="/accomplishments" />
                                        <CardItem
                    src="images\Cybrary_Malware_Analysis.jpg"
                    text="Malware Analysis"
                    label="InfoSec"
                    path="/accomplishments" />
                    <CardItem
                    src="images\Cybrary_Social_Engineering.jpg"
                    text="Social Engineering"
                    label="InfoSec"
                    path="/accomplishments" />
                </ul>
            </div> 
            
            
            
        </div>
    )
}

export default Cards
